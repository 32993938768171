














































































import Vue from "vue";
import { mapState } from "vuex";
import { download } from "@/utils";

export default Vue.extend({
  data() {
    return {
      time: " "
    };
  },
  computed: {
    ...mapState("app", {
      isCN: "isCN",
      isMobile: "isMobile"
    }),
    ...mapState("assets", {
      oss: "oss",
      url: "url"
    }),
    image01(): string {
      if (this.isMobile) {
        return this.isCN
          ? this.oss.cyberboard_gb_01_mobile_cn
          : this.oss.cyberboard_gb_01_mobile_en;
      } else {
        return this.isCN
          ? this.oss.cyberboard_gb_01_cn
          : this.oss.cyberboard_gb_01_en;
      }
    }
  },
  methods: {
    onDownload() {
      download(this.oss.pdf_cyberboard_xmas);
    },
    onVerify() {
      window.open(this.$paths.verify + "cyberboard/", "_blank");
    }
  }
});
